import { logout, setCredentals } from 'store/auth'
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { AppState } from 'store'
import { Mutex } from 'async-mutex'
import { LoginResponse, baseQuery as baseAuthQuery } from './index'

interface Response<T> {
  errors: Error | null
  data: T
}

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AUTHZ_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as AppState).auth.accessToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const mutex = new Mutex()

const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // Using mutex to lock query, only 1 refresh should be call
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseAuthQuery(
          {
            url: '/users/refresh-token/',
            method: 'POST',
            body: {
              refresh_token: (api.getState() as AppState).auth.refreshToken,
            },
          },
          api,
          extraOptions,
        )

        if (refreshResult.data) {
          const res = refreshResult.data as LoginResponse

          api.dispatch(
            setCredentals({
              accessToken: res.access_token,
              refreshToken: res.refresh_token,
            }),
          )

          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logout())
        }
      } finally {
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

export const authzApi = createApi({
  reducerPath: 'authz',
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    getUserGroup: builder.query<Response<string[]>, string>({
      query: (email) => ({
        url: `/authz/user_group/${email}`,
      }),
    }),
    checkPermission: builder.mutation({
      query: (body) => ({
        url: '/authz',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useGetUserGroupQuery, useCheckPermissionMutation } = authzApi
