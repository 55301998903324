import { createSlice } from '@reduxjs/toolkit'

interface UserState {
  showSidebar: boolean
}

const initialState: UserState = {
  showSidebar: true,
}

const authSlide = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleSidebar(state) {
      const prevState = state.showSidebar
      state.showSidebar = !prevState
    },
  },
})

export const { toggleSidebar } = authSlide.actions

export default authSlide.reducer
