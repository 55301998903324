import { toast } from 'react-toastify'
import {
  DatapalLogoWeb,
  Banner,
  DatapalNetwork,
  IconCheckYellow,
  LandingDataSource,
  LandingBigData,
  FbLogo,
  GgAnalyticsLogo,
  BingAdsLogo,
  AdRollLogo,
  LinkedInLogo,
  HubSpotLogo,
  DV360Logo,
  YouTubeLogo,
  SnowflakeLogo,
  LandingAttribution,
  // LandingAutomate,
  ContactMail,
  ContactPhone,
  ContactLocation,
  // IconMenu,
  X,
} from 'assets/images'
import { Link, useNavigate } from 'react-router-dom'
import { CImage, CSpinner } from '@coreui/react'
import {
  Container,
  Header,
  HideUnder768,
  Intro,
  // IntroContact,
  IntroDesc,
  // IntroFooter,
  IntroImage,
  IntroLeft,
  IntroText,
  MenuItem,
  MenuWrapper,
  Section1,
  PageContainer,
  // Partner,
  SignInButton,
  Title,
  Section2,
  Button,
  // Section2BtnGroup,
  // OutlineBtn,
  Section3Desktop,
  Section3Mobile,
  DatasourceGrid,
  DatasourceItem,
  YellowCard,
  AbsoluteImg,
  SetupAttribution,
  // Automate,
  DataSourceTitle,
  ContactUs,
  ContactUsContent,
  Input,
  ShowAbove768,
  MenuIcon,
  Sidebar,
  Backdrop,
  SidebarHeader,
  TextArea,
} from './styleds'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { cibFacebook, cibInstagram, cibTwitter } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useEffect, useRef, useState } from 'react'
import { useAuth } from 'store/auth/hooks'
import { useContactUsMutation } from 'services/api'

const menu: { id: string; name: string }[] = [
  // {
  //   id: 'product',
  //   name: 'Product',
  // },
  // {
  //   id: 'solutions',
  //   name: 'Solutions',
  // },
  // {
  //   id: 'intergration',
  //   name: 'Intergrations',
  // },
  // {
  //   id: 'pricing',
  //   name: 'Pricing',
  // },
  // {
  //   id: 'customers',
  //   name: 'Customers',
  // },
  // {
  //   id: 'company',
  //   name: 'Company',
  // },
]

function Landing() {
  const now = new Date()
  const { width } = useWindowDimensions()
  const datasource = (
    <DatasourceGrid>
      <DatasourceItem>
        <img src={FbLogo} alt="" />
      </DatasourceItem>
      <DatasourceItem>
        <img src={GgAnalyticsLogo} alt="" />
      </DatasourceItem>
      <DatasourceItem>
        <img src={BingAdsLogo} alt="" />
      </DatasourceItem>
      <DatasourceItem>
        <img src={AdRollLogo} alt="" />
      </DatasourceItem>
      <DatasourceItem>
        <img src={YouTubeLogo} alt="" />
      </DatasourceItem>
      <DatasourceItem>
        <img src={LinkedInLogo} alt="" />
      </DatasourceItem>
      <DatasourceItem>
        <img src={HubSpotLogo} alt="" />
      </DatasourceItem>
      <DatasourceItem>
        <img src={DV360Logo} alt="" />
      </DatasourceItem>
      <DatasourceItem>
        <img src={SnowflakeLogo} alt="" />
      </DatasourceItem>
    </DatasourceGrid>
  )

  const [showSidebar, setShowSidebar] = useState(false)
  const navigate = useNavigate()
  const { isLoggedIn } = useAuth()

  const contactUsRef = useRef<HTMLDivElement>(null)

  const [sendContact, { reset, isLoading, error, data }] = useContactUsMutation()
  const [email, setEmail] = useState('')
  const [fullname, setFullName] = useState('')
  const [content, setContent] = useState('')

  useEffect(() => {
    if (data) {
      toast.success('Summited successfully', { theme: 'colored' })
      reset()
      setEmail('')
      setContent('')
      setFullName('')
    }
  }, [data, reset])
  useEffect(() => {
    if (error) {
      toast.error(JSON.stringify(error), { theme: 'colored' })
      reset()
    }
  }, [error, reset])

  const handleSubmit = () => {
    if (!email.trim()) toast.error('Please input your email!', { theme: 'colored' })
    if (!fullname.trim()) toast.error('Please input your name!', { theme: 'colored' })
    if (!content.trim()) toast.error('Please input content!', { theme: 'colored' })

    if (email.trim() && fullname.trim() && content.trim()) {
      sendContact({
        email: email.trim(),
        full_name: fullname.trim(),
        content: content.trim(),
      })
    }
  }

  return (
    <PageContainer>
      <Sidebar show={showSidebar}>
        <div style={{ padding: '1.5rem' }}>
          <SidebarHeader>
            <Link to="/landing">
              <img src={DatapalLogoWeb} alt="Datapal" height="24" />
            </Link>

            <MenuIcon
              style={{ width: '24px' }}
              onClick={() => {
                setShowSidebar(false)
              }}
            >
              <img src={X} alt="" width="100%" />
            </MenuIcon>
          </SidebarHeader>
          <div className="d-flex" style={{ flexDirection: 'column', gap: '16px', padding: '24px' }}>
            {menu.map((item) => (
              <MenuItem to="/landing" key={item.name}>
                {item.name}
              </MenuItem>
            ))}
            <SignInButton
              style={{ marginTop: '1rem' }}
              onClick={() => (isLoggedIn ? navigate('/dashboard') : navigate('/login'))}
            >
              {isLoggedIn ? 'Dashboard' : 'SIGN IN'}
            </SignInButton>
          </div>
        </div>
      </Sidebar>
      {showSidebar && <Backdrop />}

      <Section1>
        <Container style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Header>
            <Link to="/landing">
              <img src={DatapalLogoWeb} alt="Datapal" height="36" />
            </Link>

            <MenuWrapper>
              {menu.map((item) => (
                <MenuItem to="/landing" key={item.name}>
                  {item.name}
                </MenuItem>
              ))}
              <SignInButton
                onClick={() => (isLoggedIn ? navigate('/dashboard') : navigate('/login'))}
              >
                {isLoggedIn ? 'Dashboard' : 'SIGN IN'}
              </SignInButton>
            </MenuWrapper>

            {/*             <MenuIcon onClick={() => setShowSidebar(true)}> */}
            {/*               <img src={IconMenu} alt="" width="100%" /> */}
            {/*             </MenuIcon> */}
          </Header>

          <Intro data-aos="fade-right">
            <IntroLeft>
              <IntroText>Unite all of your data.</IntroText>
              <IntroText>One source of truth.</IntroText>
              <IntroText> Uncovered opportunities.</IntroText>

              <IntroDesc>
                Connect any marketing data source to your dashboard. Let your marketers focus on the
                analysis. Datapal takes care of the up-to-date insights.
              </IntroDesc>

              <HideUnder768>
                <Button
                  onClick={() => contactUsRef.current?.scrollIntoView({ behavior: 'smooth' })}
                >
                  Contact us for a live demo
                </Button>
              </HideUnder768>
            </IntroLeft>
            <IntroImage data-aos="fade-left" className="text-center">
              <img src={Banner} alt="intro img" />
            </IntroImage>

            <ShowAbove768>
              <Button
                onClick={() => contactUsRef.current?.scrollIntoView({ behavior: 'smooth' })}
                className="text-center m-auto mt-4"
              >
                Contact us for a live demo
              </Button>
            </ShowAbove768>
          </Intro>
        </Container>

        {/* <IntroFooter> */}
        {/*   <Container> */}
        {/*     <Partner> */}
        {/*       <CImage fluid src={AsusLogo} /> */}
        {/*       <CImage fluid src={IllyLogo} /> */}
        {/*       <CImage fluid src={AdRoll} /> */}
        {/*       <CImage fluid src={AncenstryLogo} /> */}
        {/*       <CImage fluid src={OmdLogo} /> */}
        {/*       <CImage fluid src={ManoLogo} /> */}
        {/*       <CImage fluid src={TeachableLogo} /> */}
        {/*     </Partner> */}
        {/*   </Container> */}
        {/* </IntroFooter> */}
      </Section1>
      <Section2>
        <Container>
          <Title data-aos="zoom-in-up">Unified modern data stack for revenue operations</Title>
          <div data-aos="zoom-in-up" style={{ textAlign: 'center', width: '80%', margin: 'auto' }}>
            Datapal helps revenue teams understand where to invest their dollars and how to multiply
            them. With the power of the modern data analytics stack, businesses can drive growth and
            increase customer loyalty by making decisions backed with actionable insights.
          </div>

          {/* <Section2BtnGroup data-aos="zoom-in-up"> */}
          {/*   <Button style={{ width: '100%', maxWidth: '333px' }}>Datapal Modern Data Stack</Button> */}
          {/*   <OutlineBtn style={{ width: '100%', maxWidth: '333px' }}> */}
          {/*     Traditional Data Stack */}
          {/*   </OutlineBtn> */}
          {/* </Section2BtnGroup> */}

          <div
            data-aos="zoom-in-up"
            className="mt-4 py-4 w-100"
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
              gap: '1.5rem',
            }}
          >
            <div className="d-flex fs-16 fw-600 align-items-center">
              <CImage fluid src={IconCheckYellow} className="me-2" width="32px" />
              No-code platform
            </div>
            <div className="d-flex fs-16 fw-600 align-items-center">
              <CImage fluid src={IconCheckYellow} className="me-2" width="32px" />
              Extract & load
            </div>
            <div className="d-flex fs-16 fw-600 align-items-center">
              <CImage fluid src={IconCheckYellow} className="me-2" width="32px" />
              Transform
            </div>
            <div className="d-flex fs-16 fw-600 align-items-center">
              <CImage fluid src={IconCheckYellow} className="me-2" width="32px" />
              Normalize
            </div>

            <div className="d-flex fs-16 fw-600 align-items-center">
              <CImage fluid src={IconCheckYellow} className="me-2" width="32px" />
              Analysis-ready data
            </div>
          </div>

          <CImage
            data-aos="zoom-in-up"
            src={DatapalNetwork}
            fluid
            style={{ maxWidth: 'min(888px, 100%)', margin: 'auto', marginTop: '24px' }}
          />
        </Container>
      </Section2>

      <Section3Desktop>
        <Container>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 3 }}>
              <img src={LandingDataSource} data-aos="fade-right" width="80%" alt="" />
            </div>

            <div
              data-aos="fade-left"
              style={{
                position: 'relative',
                flex: 4,
                textAlign: 'left',
                padding: '36px 0 48px',
                height: 'fit-content',
              }}
            >
              <DataSourceTitle>Extract data from various data source.</DataSourceTitle>
              <p style={{ color: '#ffffff' }}>
                With Datapal, you can get data for any of your marketing needs and load it to the
                warehouse of your choice.
              </p>

              {/* <div style={{ marginTop: '64px', display: 'flex', gap: '1rem' }}> */}
              {/*   <Button>Data sources</Button> */}
              {/*   <OutlineBtn style={{ color: '#fff', borderColor: '#fff' }}> */}
              {/*     Data warehoures */}
              {/*   </OutlineBtn> */}
              {/*   <OutlineBtn style={{ color: '#fff', borderColor: '#fff' }}> */}
              {/*     Visualization platforms */}
              {/*   </OutlineBtn> */}
              {/* </div> */}

              {datasource}
            </div>
          </div>
        </Container>
      </Section3Desktop>
      <Section3Mobile>
        <Container style={{ position: 'relative' }} data-aos="zoom-in-up">
          <div className="d-flex align-items-center first-div">
            <div style={{ flex: 2 }}>
              <img src={LandingDataSource} width="100%" alt="" />
            </div>
            <div style={{ flex: 3 }}>
              <DataSourceTitle>Extract data from various data source.</DataSourceTitle>

              <p style={{ color: '#ffffff' }}>
                With Datapal, you can get data for any of your marketing needs and load it to the
                warehouse of your choice.
              </p>
            </div>
          </div>

          {/* <div className="btn-group"> */}
          {/*   <Button>Data sources</Button> */}
          {/*   <OutlineBtn style={{ color: '#fff', borderColor: '#fff' }}>Data warehoures</OutlineBtn> */}
          {/*   <OutlineBtn style={{ color: '#fff', borderColor: '#fff' }}> */}
          {/*     Visualization platforms */}
          {/*   </OutlineBtn> */}
          {/* </div> */}

          {datasource}
        </Container>
      </Section3Mobile>

      <Container data-aos="zoom-in-up">
        <Title style={{ maxWidth: '600px', margin: 'auto' }}>
          Centralize all your marketing and sales data in one place
        </Title>

        <div style={{ margin: 'auto', maxWidth: '768px', textAlign: 'center', marginTop: '16px' }}>
          Ready to jump into a more productive, meaningful and enjoyable collaborative note taking
          experience? Here’s what makes Datapal your perfect companion.
        </div>

        {/* <Button style={{ margin: 'auto', marginTop: '36px', minWidth: '300px' }}> */}
        {/*   Explore the full catalog */}
        {/* </Button> */}
      </Container>

      <Container className="mt-5">
        <div data-aos="fade-right">
          <div className="d-flex fs-16 fw-600 align-items-center">
            <CImage fluid src={IconCheckYellow} className="me-2" width="32px" />
            Various connectors empower your revenue team.
          </div>
          <div className="d-flex fs-16 fw-600 align-items-center mt-4">
            <CImage fluid src={IconCheckYellow} className="me-2" width="32px" />
            Load analysis-ready data to your own data warehouse.
          </div>
          <div className="d-flex fs-16 fw-600 align-items-center mt-4">
            <CImage fluid src={IconCheckYellow} className="me-2" width="32px" />
            Visualize data using your favorite tools.
          </div>
        </div>

        <YellowCard data-aos="zoom-in-up">
          <Title
            style={{
              textAlign: 'left',
              width: width < 969 ? '70%' : '60%',
            }}
          >
            Leave the big data transformation to Datapal
          </Title>
          <div
            style={{
              width: width < 550 ? '100%' : '70%',
              paddingBottom: width < 992 ? '50px' : '80px',
            }}
          >
            Use Datapal’s data transformation tool to perform the simple or complex data
            transformations your business needs.
          </div>

          {/* <Button style={{ background: '#fff', marginTop: '36px' }}> */}
          {/*   Explore the full catalog */}
          {/* </Button> */}

          <AbsoluteImg>
            <img src={LandingBigData} alt="" width="100%" />
          </AbsoluteImg>
        </YellowCard>
      </Container>

      <Container className="py-5">
        <SetupAttribution>
          <div className="first" data-aos="fade-right">
            <img src={LandingAttribution} alt="" width="80%" />
          </div>

          <div className="second" data-aos="fade-left">
            <Title>Set up attribution models to track your revenue streams</Title>
            <div>
              Free up more time for your team to drive growth by automating your revenue analytics.
              Build a full picture of your ROI data with cross-device identity resolution in a
              single dashboard
            </div>

            <div className="mobile-img">
              <img src={LandingAttribution} alt="" width="80%" />
            </div>

            {/* <Button style={{ marginTop: '36px', minWidth: '200px' }}>Explore</Button> */}
          </div>
        </SetupAttribution>
      </Container>

      {/* <Automate data-aos="zoom-in-up"> */}
      {/*   <Container> */}
      {/*     <DataSourceTitle style={{ textAlign: 'center' }}> */}
      {/*       Automate cross-channel normalization */}
      {/*     </DataSourceTitle> */}

      {/*     <img */}
      {/*       src={LandingAutomate} */}
      {/*       alt="" */}
      {/*       style={{ marginTop: width < 993 ? '0' : '36px' }} */}
      {/*       width="100%" */}
      {/*     /> */}
      {/*   </Container> */}
      {/* </Automate> */}

      <ContactUs ref={contactUsRef}>
        <Container>
          <Title>Contact us now to unleash the potential of your raw data</Title>

          <ContactUsContent>
            <div
              style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              data-aos="fade-right"
            >
              <div>
                {/* <div> */}
                {/*   Datapal automates the annoying parts of data management. No more manual anything. */}
                {/*   Just automate */}
                {/* </div> */}

                <div className="d-flex align-items-center">
                  <img src={ContactLocation} alt="" width="32px" className="me-2" />
                  5011 Carrieridge Dr, Columbia, MO 65203
                </div>

                <div className="d-flex align-items-center mt-2">
                  <img src={ContactPhone} alt="" width="32px" className="me-2" />
                  573-639-0721
                </div>

                <div className="d-flex align-items-center mt-2">
                  <img src={ContactMail} alt="" width="32px" className="me-2" />
                  support@datapal.ai
                </div>

                <div className="d-flex mt-4" style={{ gap: '1rem' }}>
                  <div className="fs-16 fw-600">Connect with us: </div>

                  <CIcon icon={cibFacebook} width={48}></CIcon>
                  <CIcon icon={cibInstagram} width={48}></CIcon>
                  <CIcon icon={cibTwitter} width={48}></CIcon>
                </div>
              </div>
            </div>

            <div
              data-aos="fade-left"
              style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
              <Input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              <Input
                placeholder="Full Name"
                value={fullname}
                onChange={(e) => setFullName(e.target.value)}
              />
              <TextArea
                placeholder="Content"
                rows={4}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
              <Button style={{ width: '100%' }} onClick={handleSubmit} disabled={isLoading}>
                {isLoading && <CSpinner />} Submit
              </Button>
            </div>
          </ContactUsContent>
        </Container>
      </ContactUs>
      <footer className="footer">
        <div>
          <span className="me-1">© {now.getFullYear()} Datapal. All Rights Reserved.</span>
        </div>
        <div className="ms-auto">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-use">Terms Of Use</Link>
        </div>
      </footer>
    </PageContainer>
  )
}

export default Landing
