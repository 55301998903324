import { createSlice } from '@reduxjs/toolkit'

export enum Type {
  DATE_RANGE = 'range',
  SPECIFIC_DATE = 'date',
}

interface ExtractionState {
  extractionSettings: {
    [connection_id: string]: {
      scheduleType: Type
      connection_id: string
      template_table_name: string
      start_date: string
      end_date: string
      business_email: string
      provider: string
      template: { id: string; name: string } | null
      sub_accounts: { id: string; name: string }[]
      schedule: string
      date_range: string
      dimensions: string[]
    }
  }
}

const initialState: ExtractionState = {
  extractionSettings: {},
}

const defaultExtractionSettings = {
  scheduleType: Type.DATE_RANGE,
  start_date: '',
  end_date: '',
  business_email: '',
  template_table_name: '',
  provider: '',
  template: null,
  sub_accounts: [],
  schedule: '',
  date_range: '',
  dimensions: [],
}

const connectionSlice = createSlice({
  name: 'connecion',
  initialState,
  reducers: {
    toggleSubAccount(state, { payload: { connection_id, provider, selected, account } }) {
      if (!state.extractionSettings[connection_id])
        state.extractionSettings[connection_id] = {
          ...defaultExtractionSettings,
          provider,
          connection_id,
        }
      let subAccounts = state.extractionSettings[connection_id].sub_accounts
      if (selected) subAccounts = [...subAccounts, account]
      else subAccounts = subAccounts.filter((item) => item.id !== account.id)
      state.extractionSettings[connection_id].sub_accounts = subAccounts
    },

    setSubAccounts(state, { payload: { connection_id, provider, accounts } }) {
      if (!state.extractionSettings[connection_id])
        state.extractionSettings[connection_id] = {
          ...defaultExtractionSettings,
          provider,
          connection_id,
        }
      state.extractionSettings[connection_id].sub_accounts = accounts
    },

    setTemplate(state, { payload: { connection_id, provider, template } }) {
      if (!state.extractionSettings[connection_id])
        state.extractionSettings[connection_id] = {
          ...defaultExtractionSettings,
          provider,
          connection_id,
        }
      state.extractionSettings[connection_id].template = template
    },

    setTemplateTableName(state, { payload: { connection_id, template_table_name } }) {
      state.extractionSettings[connection_id].template_table_name = template_table_name
    },

    setDateRange(state, { payload: { connection_id, date_range } }) {
      state.extractionSettings[connection_id].date_range = date_range
    },

    setSchedule(state, { payload: { connection_id, schedule } }) {
      state.extractionSettings[connection_id].schedule = schedule
    },

    setStartDate(state, { payload: { connection_id, start_date } }) {
      state.extractionSettings[connection_id].start_date = start_date
    },

    setEndDate(state, { payload: { connection_id, end_date } }) {
      state.extractionSettings[connection_id].end_date = end_date
    },

    setType(state, { payload: { connection_id, type } }) {
      state.extractionSettings[connection_id].scheduleType = type
    },
  },
})

export const {
  toggleSubAccount,
  setSubAccounts,
  setTemplate,
  setTemplateTableName,
  setDateRange,
  setSchedule,
  setStartDate,
  setEndDate,
  setType,
} = connectionSlice.actions

export default connectionSlice.reducer
