import { AppState } from 'store'
import { logout, setCredentals } from 'store/auth'
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { LoginResponse, baseQuery as baseAuthQuery } from '../auth'
import { read, utils } from 'xlsx'
import { MAX_LIMIT, isJson } from 'utils'

interface NameValidatorResponse {
  object: {
    error_message: string[]
    good_naming_offer: string[]
    pos_score: [
      {
        entity_group: string
        score: number
        word: string
        pos: number
        fuzzy: boolean
      },
    ][][]
  }
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_NCONV_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as AppState).auth.accessToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const mutex = new Mutex()

const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // Using mutex to lock query, only 1 refresh should be call
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseAuthQuery(
          {
            url: '/users/refresh-token/',
            method: 'POST',
            body: {
              refresh_token: (api.getState() as AppState).auth.refreshToken,
            },
          },
          api,
          extraOptions,
        )

        if (refreshResult.data) {
          const res = refreshResult.data as LoginResponse

          api.dispatch(
            setCredentals({
              accessToken: res.access_token,
              refreshToken: res.refresh_token,
            }),
          )

          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logout())
        }
      } finally {
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

export const nconvApi = createApi({
  reducerPath: 'nconv',
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    categories: builder.query<{ category: string; name: string }[], string>({
      query: (type) => ({
        url: `/nlp-tools/category?category_type=${type}`,
      }),
    }),

    validateName: builder.mutation<
      NameValidatorResponse,
      {
        name: string
        pattern: string[]
        type: string
        delimiter: string
        builder_check?: boolean
      }
    >({
      query: ({ name, pattern, type, delimiter, builder_check = true }) => ({
        url: '/nlp-tools/nconv-check',
        method: 'POST',
        body: {
          name,
          pattern,
          type,
          delimiter,
          builder_check,
        },
      }),
    }),

    checkNameFile: builder.mutation({
      query: (body) => ({
        url: '/nlp-tools/nconv-file-check/',
        method: 'POST',
        body,
      }),
    }),

    categoryTemplates: builder.query<
      {
        data: Array<{
          id: string
          label: string | null
          categories: string[]
          type: string
        }>
      },
      { q: string; type: string }
    >({
      query: ({ q, type }) => ({
        url: '/nlp-tools/user-category-template',
        params: { q, type, limit: MAX_LIMIT },
      }),
    }),

    createCatTemplate: builder.mutation({
      query: (body) => ({
        url: '/nlp-tools/user-category-template',
        method: 'POST',
        body,
      }),
    }),

    deleteUserTemplate: builder.mutation<{ status: string }, string>({
      query: (id) => ({
        url: `/nlp-tools/user-category-template/${id}`,
        method: 'DELETE',
      }),
    }),

    categoryValue: builder.query({
      query: ({ category, type }) => ({
        url: `/nlp-tools/category-values?category=${category}&type=${type}`,
      }),
    }),

    generateId: builder.mutation<string, string>({
      query: (name: string) => ({
        url: `/nlp-tools/auto-id`,
        params: { name },
      }),
    }),

    createCampaignName: builder.mutation<
      void,
      {
        type: string
        name_content: string[]
        delimiter: string
        template_id: string
      }[]
    >({
      query: (body) => ({
        url: '/nlp-tools/campaign-name',
        method: 'POST',
        body,
      }),
    }),

    campaignNames: builder.query<
      {
        data: {
          id: string
          value: string
          type: string
          template: {
            id: string
            categories: string[]
          }
          delimiter: string
          name_content: string[]
        }[]
        totalItems: number
      },
      { page: number; limit: number }
    >({
      query: (params) => ({
        url: `/nlp-tools/campaign-name?state=Confirmed&order_by=-create_at`,
        params,
      }),
    }),

    deleteCampaignName: builder.mutation<void, string>({
      query: (id) => ({
        url: `/nlp-tools/campaign-name/${id}`,
        method: 'DELETE',
      }),
    }),

    checkFile: builder.mutation({
      query: (body) => ({
        url: '/nlp-tools/nconv-file-check',
        method: 'POST',
        body,
        headers: {
          contentType: 'multipart/form-data',
        },
        responseHandler: async (res) => {
          const data = await res.arrayBuffer()

          const wb = read(data)
          const dataJson = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
          return dataJson.map((item: any) => {
            return {
              ...item,
              error_message: isJson(item.error_message)
                ? JSON.parse(item.error_message)
                : JSON.parse(item.error_message.replaceAll("'", '"').replaceAll('True', true)),
              good_naming_offer: isJson(item.good_naming_offer)
                ? JSON.parse(item.good_naming_offer)
                : JSON.parse(item.good_naming_offer.replaceAll("'", '"').replaceAll('True', true)),
              pos_score: JSON.parse(
                item.pos_score.replaceAll("'", '"').replaceAll('True', true),
              ).flat(),
            }
          })
        },
      }),
    }),

    validateNameBuilder: builder.mutation<
      Array<{
        combination: { [key: string]: string }
        score: { [key: string]: number }
      }>,
      {
        d_placement: { [key: string]: string[] }
        type: string
        delimiter: string
      }
    >({
      query: (body) => ({
        url: `/nlp-tools/name-builder-check`,
        method: 'POST',
        body,
      }),
    }),

    nameAction: builder.query({
      query: (params) => ({
        url: '/nlp-tools/name-action',
        params,
      }),
    }),
    categoryList: builder.query<string[], void>({
      query: () => ({
        url: `/nlp-tools/list-name-type`,
      }),
    }),
  }),
})

export const {
  useCategoriesQuery,
  useValidateNameMutation,
  useCategoryTemplatesQuery,
  useDeleteUserTemplateMutation,
  useCreateCatTemplateMutation,
  useCategoryValueQuery,
  useGenerateIdMutation,
  useCreateCampaignNameMutation,
  useCampaignNamesQuery,
  useDeleteCampaignNameMutation,
  useCheckFileMutation,
  useValidateNameBuilderMutation,
  useNameActionQuery,
  useCategoryListQuery,
} = nconvApi
