import { createSlice } from '@reduxjs/toolkit'

interface AuthState {
  readonly accessToken: string
  readonly refreshToken: string
}

const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
}

const authSlide = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentals(state, { payload }) {
      state.accessToken = payload.accessToken
      state.refreshToken = payload.refreshToken
    },
    logout(state) {
      state.accessToken = ''
      state.refreshToken = ''
    },
  },
})

export const { setCredentals, logout } = authSlide.actions

export default authSlide.reducer
