import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const PageContainer = styled.div`
  font-family: 'Poppins';
  overflow: hidden;
`

export const Container = styled.div`
  max-width: 1320px;
  padding: 0 1rem;
  margin: auto;
`

export const Section1 = styled.div`
  background: #231f20;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
`

export const Button = styled.button`
  outline: none;
  border: none;
  background: #fcd118;
  border-radius: 8px;
  border-bottom-right-radius: 0;
  height: 44px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: max-content;
  padding: 0 1.5rem;
  :active {
    transform: scale(0.94);
  }
`

export const SignInButton = styled(Button)`
  border-radius: 999px;
  padding: 0 1.75rem;
  height: 36px;
`

export const OutlineBtn = styled(Button)`
  background: transparent;
  border: 1px solid #231f20;
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 16px;

  @media only screen and (max-width: 1100px) {
    gap: 1.5rem;
    font-size: 14px;

    ${SignInButton} {
      font-size: 14px;
    }
  }

  // @media only screen and (max-width: 992px) {
  //   display: none;
  // }
`
export const MenuIcon = styled.button`
  display: none;
  @media only screen and (max-width: 992px) {
    outline: none;
    border: none;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    width: 36px;
    height: 36px;

    :active {
      transform: scale(0.96);
    }

    img {
      object-fit: contain;
    }
  }
`

export const MenuItem = styled(Link)`
  text-transform: uppercase;
  color: #fcd118;
  font-weight: 600;
  text-decoration: none;

  :hover {
    color: #fff;
  }
`

export const Intro = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 64px 0;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const IntroLeft = styled.div`
  flex: 1;
`

export const IntroText = styled.h2`
  color: #fcd118;
  font-weight: 600;
  font-size: 44px;

  @media only screen and (max-width: 1182px) {
    font-size: 36px;
  }

  @media only screen and (max-width: 992px) {
    font-size: 28px;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
    font-size: 24px;
  }
`
export const IntroDesc = styled.p`
  max-width: 472px;
  font-size: 16px;
  color: #fff;

  @media only screen and (max-width: 992px) {
    max-width: 400px;
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`

export const HideUnder768 = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const ShowAbove768 = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
    margin: auto;
  }
`

export const IntroContact = styled.button`
  outline: none;
  border: none;
  background: transparent;
  margin-top: 24px;
  font-size: 28px;
  font-weight: 600;
  color: #fcd118;

  :hover {
    color: #ffffff;
  }

  @media only screen and (max-width: 992px) {
    font-size: 20px;
  }
`
export const IntroImage = styled.div`
  img {
    object-fit: contain;
    width: 100%;

    @media only screen and (max-width: 768px) {
      width: 80%;
    }
  }
  flex: 1;
`

export const IntroFooter = styled.div`
  background: #333333;
`

export const Partner = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  justify-items: center;
`

export const Title = styled.h3`
  font-size: 36px;
  text-align: center;
  font-weight: 600;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
`

export const Section2 = styled.div`
  padding: 72px 0;
  text-align: center;
`

export const Section2BtnGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-top: 64px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Section3Desktop = styled.div`
  padding: 48px 0 0;
  position: relative;
  background: linear-gradient(to bottom, #333333 75%, #ffffff 25%);

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export const Section3Mobile = styled.div`
  background: linear-gradient(to bottom, #333333 80%, #ffffff 20%);
  display: none;
  @media only screen and (max-width: 1024px) {
    display: block;
    padding: 24px 0 36px;
  }

  .first-div {
    @media only screen and (max-width: 500px) {
      flex-direction: column;
      gap: 24px;
    }
  }

  .btn-group {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 16px;
    flex-wrap: wrap;
  }
`

export const DatasourceGrid = styled.div`
  padding: 24px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 24px;
  background: #fcd118;
  height: max-content;
  margin-top: 48px;
`

export const DatasourceItem = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #fff;

  > img {
    object-fit: contain;
    width: 100%;
  }
`

export const DataSourceTitle = styled(Title)`
  color: #fcd118;
  text-align: left;

  @media only screen and (max-width: 992px) {
    margin-bottom: 48px;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 24px;
  }
`

export const YellowCard = styled.div`
  background: #fcd118;
  border-radius: 1rem;
  padding: 32px 24px;
  margin-top: 72px;
  text-align: left;
  position: relative;
`

export const AbsoluteImg = styled.div`
  position: absolute;
  right: -40px;
  width: 40%;
  bottom: 20%;

  @media only screen and (max-width: 1368px) {
    right: -16px;
  }

  @media only screen and (max-width: 992px) {
    right: -16px;
    bottom: 40%;
  }

  @media only screen and (max-width: 880px) {
    right: -16px;
    bottom: 60%;
  }

  @media only screen and (max-width: 550px) {
    right: -16px;
    bottom: 70%;
  }

  @media only screen and (max-width: 440px) {
    right: -16px;
    bottom: 80%;
  }
`

export const SetupAttribution = styled.div`
  display: flex;
  align-items: center;

  .first {
    flex: 1;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .second {
    flex: 1;
    @media only screen and (max-width: 768px) {
      text-align: center;

      > button {
        margin: auto;
      }
    }
  }

  ${Title} {
    text-align: left;

    @media only screen and (max-width: 768px) {
      text-align: center;
    }
  }

  .mobile-img {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
      width: 70%;
      margin: auto;
      margin-top: 24px;
    }
  }
`

export const Automate = styled.div`
  background: #333333;
  padding: 36px;
`

export const ContactUs = styled.div`
  background: #333333;
  color: #fff;
  padding: 48px 0;
`

export const ContactUsContent = styled.div`
  display: flex;
  margin-top: 36px;
  gap: 24px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Input = styled.input`
  outline: none;
  border: 1px solid #231f20;
  color: #231f20;
  padding: 12px 1rem;
  border-radius: 8px;
  width: 100%;
  border-bottom-right-radius: 0;
`

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000;
  opacity: 0.6;
  z-index: 1;
`
export const Sidebar = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: ${({ show }) => (show ? '80%' : 0)};
  max-width: 350px;
  background: #262525;
  z-index: 2;
  transition: 0.3s;
  overflow: hidden;
`

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TextArea = styled.textarea`
  outline: none;
  border: 1px solid #231f20;
  color: #231f20;
  padding: 12px 1rem;
  border-radius: 8px;
  width: 100%;
  border-bottom-right-radius: 0;
`
