import { logout, setCredentals } from 'store/auth'
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { AppState } from 'store'
import { Mutex } from 'async-mutex'
import { ResponseUser } from 'models'

export interface LoginPayload {
  email: string
  password: string
}

export interface LoginResponse {
  access_token: string
  refresh_token: string
  id_token: string
  token_type: string
}

export interface SignUpPayload {
  name: string
  email: string
  password1: string
  password2: string
}

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AUTH_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as AppState).auth.accessToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const mutex = new Mutex()

const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // Using mutex to lock query, only 1 refresh should be call
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQuery(
          {
            url: '/users/refresh-token/',
            method: 'POST',
            body: {
              refresh_token: (api.getState() as AppState).auth.refreshToken,
            },
          },
          api,
          extraOptions,
        )

        if (refreshResult.data) {
          const res = refreshResult.data as LoginResponse

          api.dispatch(
            setCredentals({
              accessToken: res.access_token,
              refreshToken: res.refresh_token,
            }),
          )

          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logout())
        }
      } finally {
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithInterceptor,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: (credentials) => ({
        url: '/users/signin/',
        method: 'POST',
        body: credentials,
      }),
    }),

    signup: builder.mutation<
      {
        user_sub: string
        user_confirmed: boolean
      },
      SignUpPayload
    >({
      query: (body) => ({
        url: '/users/signup/',
        method: 'POST',
        body,
      }),
    }),

    confirmSignup: builder.mutation<
      { user_confirmed: boolean },
      { confirmation_code: string; email: string }
    >({
      query: (body) => ({
        url: '/users/confirm-signup/',
        method: 'POST',
        body,
      }),
    }),

    users: builder.query({
      query: (params) => ({
        url: `/users/list`,
        params,
      }),
    }),

    inviteUser: builder.mutation<void, { name: string; email: string; organization_id?: string }>({
      query: (body) => ({
        url: '/users/invitation',
        method: 'POST',
        body,
      }),
    }),

    pendingUsers: builder.query({
      query: (params) => ({
        url: `/users/list/status`,
        params,
      }),
    }),

    confirmInvite: builder.mutation<LoginResponse, LoginPayload>({
      query: (credentials) => ({
        url: '/users/invitation/signin',
        method: 'POST',
        body: credentials,
      }),
    }),

    initPassword: builder.mutation({
      query: (body) => ({
        url: '/users/invitation/set-new-password',
        method: 'POST',
        body,
      }),
    }),

    confirmedUsers: builder.query({
      query: (params) => ({
        url: `/auth/users/list/status`,
        params: params,
      }),
    }),

    deleteUser: builder.mutation<ResponseUser, string>({
      query: (email) => ({
        url: `/users/delete-user/${email}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useSignupMutation,
  useConfirmSignupMutation,
  useUsersQuery,
  useInviteUserMutation,
  usePendingUsersQuery,
  useConfirmInviteMutation,
  useInitPasswordMutation,
  useConfirmedUsersQuery,
  useDeleteUserMutation,
} = authApi
