import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { logout as logoutAction } from '.'

export const useAuth = () => {
  const auth = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  const logout = useCallback(() => {
    dispatch(logoutAction())
  }, [dispatch])

  return {
    ...auth,
    isLoggedIn: !!auth.accessToken,
    logout,
  }
}
