import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import './App.css'
import './scss/style.scss'
import Landing from 'views/pages/landing-page'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const ProtectedLayout = React.lazy(() => import('./layout/ProtectedLayout'))

// Pages
const LoginPage = React.lazy(() => import('./views/pages/login'))
const RegisterPage = React.lazy(() => import('./views/pages/register'))
const ConfirmSignUp = React.lazy(() => import('./views/pages/confirm-signup/ConfirmSignup'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ConfirmInvitationLazy = React.lazy(
  () => import('./views/extract&load/organizations/ConfirmInvitation'),
)
class App extends Component {
  render() {
    return (
      <>
        <ToastContainer />
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/sign-up" element={<RegisterPage />} />
              <Route path="/confirm-signup" element={<ConfirmSignUp />} />
              <Route path="/confirm-invite" element={<ConfirmInvitationLazy />} />
              <Route path="/404" element={<Page404 />} />
              <Route path="/500" element={<Page500 />} />
              <Route path="/landing" element={<Landing />} />
              <Route path="*" element={<ProtectedLayout />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </>
    )
  }
}

export default App
