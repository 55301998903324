import { AppState } from 'store'
import { logout, setCredentals } from 'store/auth'
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { LoginResponse, baseQuery as baseAuthQuery } from './auth'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as AppState).auth.accessToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const mutex = new Mutex()

const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // Using mutex to lock query, only 1 refresh should be call
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseAuthQuery(
          {
            url: '/users/refresh-token/',
            method: 'POST',
            body: {
              refresh_token: (api.getState() as AppState).auth.refreshToken,
            },
          },
          api,
          extraOptions,
        )

        if (refreshResult.data) {
          const res = refreshResult.data as LoginResponse

          api.dispatch(
            setCredentals({
              accessToken: res.access_token,
              refreshToken: res.refresh_token,
            }),
          )

          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logout())
        }
      } finally {
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithInterceptor,
  endpoints: (build) => ({
    contactUs: build.mutation({
      query: (body) => ({
        url: `/contacts`,
        body,
        method: 'POST',
      }),
    }),
  }),
})

export const { useContactUsMutation } = api
