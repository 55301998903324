import { createBrowserHistory } from 'history'

const history = createBrowserHistory({ window })

export function isJson(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const MAX_LIMIT = 1000

export const isAdminLoggedIn = (organizations: string[] | undefined) => {
  if (organizations && organizations.length > 0) {
    return organizations.some((str) => str.indexOf('_admin') !== -1)
  }

  return false
}

export default history
